import React, { useContext, useEffect, useState } from 'react'
import { App, Button, Card, Col, Form, Row, Select, Steps } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import FormStep0 from './FormStep0'
import FormStep1 from './FormStep1'
import FormStep2 from './FormStep2'
import FormStep3 from './FormStep3'
import FormStep4 from './FormStep4'
import FormStep5 from './FormStep5'
import FormStep6 from './FormStep6'
import FormStep7 from './FormStep7'
import { AccountContext } from '../../../../utils/Auth'
import usePWAInstall from '../../../../utils/usePWAInstall'
import dayjs from 'dayjs'
import { useLocalDb } from '../../../../utils/useLocalDb'

const ModificarUsina = ({
                            onStepChange,
                            formType,
                        }: {
    onStepChange: (value: number) => void,
    formType: any,
}) => {
    const { id } = useParams()
    const { authenticatedFetch } = useContext(AccountContext)
    const [form] = Form.useForm()
    const { message } = App.useApp()
    const navigate = useNavigate()
    const { isInstalled } = usePWAInstall()
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [usinaData, setUsinaData] = useState<any>({})

    useEffect(() => {
        if (id !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/usinas?idUsina=' + id,
                'GET',
            )
                .then(
                    (res: {
                        ok: any
                        status: number
                        json: () => Promise<any[]>
                    }) => {
                        if (res.ok) {
                            res.json().then((data: any) => {
                                const formattedData = { ...data }

                                if (formattedData.dataInstalacao !== null) {
                                    formattedData.dataInstalacao = dayjs(formattedData.dataInstalacao)
                                }
                                if (formattedData.dataInicioOperacao !== null) {
                                    formattedData.dataInicioOperacao = dayjs(formattedData.dataInicioOperacao)
                                }

                                for (const [key, value] of Object.entries(formattedData)) {
                                    if (value === null &&
                                        (key !== 'dataInicioOperacao') &&
                                        (key !== 'dataInstalacao')) {
                                        formattedData[key] = ''
                                        if (key === 'telefone2' || key === 'nome') {
                                            formattedData[key] = ''
                                        }
                                    }
                                    if (key === 'satisfacaoProprietario') {
                                        formattedData[key] = 'NULO'
                                    }
                                    if (value === 'NULO') {
                                        formattedData[key] = ''
                                    }
                                }

                                form.setFieldsValue(formattedData)

                                let selectedData = {}
                                switch (formType) {
                                    case 'PROD':
                                        selectedData = {
                                            tipoUsina: data.tipoUsina,
                                            empresaInstaladora: data.empresaInstaladora,
                                            dataInicioOperacao: dayjs(data.dataInicioOperacao),
                                        }
                                        break
                                    case ('TECNICA' || 'EXT'):
                                        selectedData = {
                                            tipoUsina: data.tipoUsina,
                                            empresaInstaladora: data.empresaInstaladora,
                                            latitude: data.latitude,
                                            longitude: data.longitude,
                                            dataInicioOperacao: dayjs(data.dataInicioOperacao),
                                            dataInstalacao: dayjs(data.dataInstalacao),
                                        }
                                        break
                                    default:
                                        console.warn('Tipo de formulário não reconhecido')
                                }

                                setUsinaData(selectedData)
                            })
                        } else if (res.status === 404) {
                            message.error({
                                key: 'usinaFetchError',
                                content: 'Usina não encontrada',
                                duration: 6,
                            })
                        }
                    },
                )
                .catch(() => {
                    message.error({
                        key: 'usinaFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 6,
                    })
                })
        }
    }, [id])

    const onFinish = () => {
        let values = form.getFieldsValue(true)

        let requestUrl = process.env.REACT_APP_SERVER_IP + '/usinas?idUsina=' + id

        authenticatedFetch(
            requestUrl,
            'PATCH',
            JSON.stringify(values),
        )
            .then((res: { status: number }) => {
                if (res.status === 200) {
                    message.success('Usina atualizada com sucesso!', 8)
                    onStepChange(currentStep + 1)
                    window.scrollTo(550, 550)
                } else {
                    message.error('Erro ao tentar cadastrar a usina', 8)
                }
            })
            .catch(() => {
                if (isInstalled) {
                    navigate(-1)
                } else {
                    message.error('Erro ao tentar contactar o servidor', 8)
                }
            })
    }


    return (
        <Form
            form={form}
            layout={'vertical'}
            onFinish={onFinish}
            requiredMark={'optional'}
        >
            <Steps
                current={currentStep}
                size={'small'}
                direction={'vertical'}
                items={[
                    {
                        title: 'Dados complementares da usina',
                        description:
                            <FormStep7
                                currentStep={currentStep}
                                formType={formType}
                            />,
                    },
                ]}
            />
            <Row>
                <Col md={24} lg={18} xl={14} xxl={10}>
                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 8, offset: 16 }}
                            xl={{ span: 6, offset: 18 }}
                        >
                            <Button
                                type="primary"
                                block
                                htmlType="submit"
                                style={{ width: '100%' }}
                            >
                                Próximo
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}


const NovaModificarInspecao = () => {
    const { id, idInspecao } = useParams()
    const { message } = App.useApp()
    const { authenticatedFetch } = useContext(AccountContext)
    const { authenticatedFetchImg } = useContext(AccountContext)
    const { isInstalled } = usePWAInstall()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [usinaData, setUsinaData] = useState<any>({})
    const [fileList, setFileList] = useState<any>([])
    const [currentStep, setCurrentStep] = useState<number>(0)
    const { isOnline, addInspecaoToLocalDb } = useLocalDb()
    const [selectFormType, setSelectFormType] = useState<any>(null)
    const [formType, setFormType] = useState<any>(null)
    const location = useLocation()

    useEffect(() => {
        // if (
        //     !location.search.includes('formType') &&
        //     !location.pathname.includes('visualizarComponente')
        // ) {
        //     // Resets form selection if users go back in history
        //     setFormType(null)
        //     setSelectFormType(null)
        // } else {
        //     let formTypeParam = location.search.slice(10)
        //     setFormType(formTypeParam)
        //     setSelectFormType(formTypeParam)
        // }
    }, [location])

    useEffect(() => {
        if (id !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/usinas?idUsina=' + id,
                'GET',
            )
                .then(
                    (res: {
                        ok: any
                        status: number
                        json: () => Promise<any[]>
                    }) => {
                        if (res.ok) {
                            res.json().then((data: any) => {
                                setUsinaData(data)
                            })
                        } else if (res.status === 404) {
                            message.error({
                                key: 'usinaFetchError',
                                content: 'Usina não encontrada',
                                duration: 6,
                            })
                        }
                    },
                )
                .catch(() => {
                    message.error({
                        key: 'usinaFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 6,
                    })
                })
        }
    }, [id])

    useEffect(() => {
        if (idInspecao !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/inspecoes?id=' + idInspecao,
                'GET',
            )
                .then(async (res: any) => {
                        if (res.ok) {
                            const data = await res.json()
                            const formattedData = { ...data }

                            delete formattedData['ultimaModificacao']
                            delete formattedData['dataCriacao']
                            formattedData['dataInstalacao'] = dayjs(
                                data.dataInstalacao,
                            )
                            formattedData['dataInicioOperacao'] = dayjs(
                                data.dataInicioOperacao,
                            )

                            setFormType(formattedData['formType'])

                            if (data.fotosKeys?.length > 0) {
                                const fotos = await Promise.all(
                                    data.fotosKeys.map(async (key: string, idx: number) => {
                                        try {
                                            const imageUrl = process.env.REACT_APP_SERVER_IP +
                                                `/inspecoes/files?fileKey=${encodeURIComponent(key)}`

                                            const response = await authenticatedFetchImg(
                                                imageUrl,
                                                'GET',
                                                null,
                                                { 'Cache-Control': 'no-cache' },
                                            )

                                            if (!response.ok) throw new Error('Falha no carregamento')

                                            const blob = await response.blob()
                                            const objectUrl = URL.createObjectURL(blob)

                                            return {
                                                uid: key,
                                                name: key.split('/').pop()?.split('?')[0] || `foto-${idx}`,
                                                status: 'done' as const,
                                                url: objectUrl,
                                            }
                                        } catch (error) {
                                            console.error('Erro ao carregar imagem:', error)
                                            return {
                                                uid: key,
                                                name: `foto-${idx}-erro`,
                                                status: 'error' as const,
                                                url: '',
                                            }
                                        }
                                    }),
                                )

                                formattedData.fotos = fotos.filter(foto => foto.status === 'done')
                            } else if (
                                formattedData['fotosKeys'] === null ||
                                formattedData['fotosKeys'].length === 0
                            ) {
                                formattedData.fotos = []
                            }

                            // Modifica os valores null para 'null' para que o formulario
                            // nao os considere como nao definidos
                            for (const [key, _] of Object.entries(
                                formattedData,
                            )) {
                                if (
                                    formattedData[key] === null &&
                                    !key.includes('Obs') &&
                                    !key.includes('FI')
                                ) {
                                    formattedData[key] = 'null'
                                }
                            }

                            form.setFieldsValue(formattedData)

                            setFormType(formattedData.formType)

                        } else if (res.status === 404) {
                            message.error({
                                key: 'inspecaoFetchError',
                                content: 'Inspeção não encontrada',
                                duration: 6,
                            })
                        }
                    },
                )
                .catch(() => {
                    message.error({
                        key: 'inspecaoFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 6,
                    })
                })
        }
    }, [id, idInspecao])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [currentStep])

    const handleStepChange = (value: number) => {
        if (value > currentStep) {
            form.validateFields()
                .then((values) => {
                    setCurrentStep(value)
                })
                .catch((error) => {
                    message.error({
                        content:
                            'Há erros no formulário, por favor corrija-os antes de continuar.',
                        key: 'formError',
                        duration: 6,
                    })
                    window.scrollTo(0, 0)
                })
        } else {
            setCurrentStep(value + 1)
        }
    }

    const uploadFotos = async (fotos: any[]) => {
        const numFotos = fotos.length
        const fotosKeys: any[] = []

        await Promise.all(
            fotos.map(async (e: any, idx: number) => {
                message.loading({
                    content: 'Enviando foto ' + (idx + 1) + ' de ' + numFotos,
                    duration: 1,
                    key: 'fotoUpload',
                })

                const uploadUrlRes = await authenticatedFetch(
                    process.env.REACT_APP_SERVER_IP +
                    '/inspecoes/imagemUploadUrl?idUsina=' + id +
                    '&fileName=' + e.name,
                    'GET',
                )
                if (!uploadUrlRes.ok)
                    throw new Error('Erro ao tentar enviar as fotos')

                const uploadUrl = await uploadUrlRes.json()

                const formData = new FormData()
                formData.append('file', e.originFileObj)

                const uploadRes = await authenticatedFetchImg(
                    process.env.REACT_APP_SERVER_IP + '/inspecoes/uploadFile?fileKey=' + uploadUrl.key,
                    'POST',
                    formData,
                    {},
                )

                if (!uploadRes.ok)
                    throw new Error('Erro ao tentar enviar as fotos')

                fotosKeys.push(uploadUrl.key)
            }),
        )

        return fotosKeys
    }

    const onFinish = async () => {
        let values = form.getFieldsValue(true)
        let requestUrl = process.env.REACT_APP_SERVER_IP + '/inspecoes'

        values.formType = formType

        Object.keys(values).forEach(function(key) {
            if (values[key] === 'null') values[key] = null
        })

        if (
            isOnline &&
            values.fotos !== undefined &&
            values.fotos !== null &&
            values.fotos.length > 0
        ) {
            let oldUploadedFotos = values.fotos.filter(
                (e: any) => e.uid && e.uid.includes('inspecao'),
            )
            oldUploadedFotos = oldUploadedFotos.map((e: any) => e.uid)

            let newFotos = values.fotos.filter(
                (e: any) => !e.uid.includes('inspecao'),
            )

            try {
                let uploadedFotos = await uploadFotos(newFotos)
                values.fotos = oldUploadedFotos.concat(uploadedFotos)
            } catch (e: any) {
                message.error({
                    key: 'fotoUploadError',
                    content: 'Erro ao tentar enviar as fotos',
                    duration: 6,
                })
                return
            }
        }

        message.destroy('fotoUpload')

        if (id !== undefined) {
            requestUrl += '?idUsina=' + id
        }

        if (idInspecao !== undefined) {
            requestUrl += '&id=' + idInspecao
        }

        authenticatedFetch(
            requestUrl,
            idInspecao ? 'PATCH' : 'POST',
            JSON.stringify(values),
        )
            .then((res: { status: number }) => {
                if (res.status === 201) {
                    message.success('Inspeção cadastrada com sucesso!', 8)
                    navigate(-1)
                } else if (res.status === 200) {
                    message.success('Inspeção atualizada com sucesso!', 8)
                    navigate(-1)
                } else {
                    message.error('Erro ao tentar cadastrar a inspeção', 8)
                }
            })
            .catch(() => {
                if (
                    isInstalled &&
                    idInspecao === undefined &&
                    typeof id === 'string'
                ) {
                    addInspecaoToLocalDb(parseInt(id), values).then(() => {
                        navigate(-1)
                        message.success('Inspeção cadastrada com sucesso!', 8)
                    })
                } else if (isOnline) {
                    message.error('Erro ao tentar contactar o servidor', 8)
                }
            })
    }

    return (
        <Card
            title={
                (idInspecao !== undefined
                    ? 'Modificar inspeção'
                    : 'Nova inspeção') +
                (formType === 'PROD'
                    ? ' (Produtor)'
                    : formType === 'EXT'
                        ? ' (Extensionista)'
                        : formType === 'TECNICA'
                            ? ' (Técnico)'
                            : '')
            }
        >
            {formType === null && idInspecao === undefined && (
                <Row>
                    <Col md={25} lg={18} xl={14} xxl={10}>
                        <div>
                            Selecione o tipo de formulário a ser preenchido:
                        </div>
                        <Select
                            value={selectFormType}
                            onChange={(e) => setSelectFormType(e)}
                            placeholder={'Tipo de formulário'}
                            style={{ width: '100%', marginTop: 8 }}
                            options={[
                                { value: 'PROD', label: 'Produtor' },
                                { value: 'EXT', label: 'Extensionista' },
                                { value: 'TECNICA', label: 'Técnico' },
                            ]}
                        />
                        <div>
                            <Button
                                type="primary"
                                block
                                style={{ marginTop: 8 }}
                                onClick={(e) => {
                                    setFormType(selectFormType)
                                }}
                            >
                                Confirmar
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
            {formType !== null && currentStep == 0 &&
                < ModificarUsina onStepChange={handleStepChange} formType={formType} />}

            {formType !== null && currentStep > 0 && (
                <Form
                    form={form}
                    layout={'vertical'}
                    onFinish={onFinish}
                    requiredMark={'optional'}
                    initialValues={{
                        paineis: [undefined],
                        inversores: [undefined],
                        transformadores: [undefined],
                    }}
                >
                    <Steps
                        current={currentStep}
                        size={'small'}
                        direction={'vertical'}
                        onChange={handleStepChange}
                        items={[
                            {
                                title: 'Infraestrutura',
                                description:
                                    currentStep === 1 ? (
                                        <FormStep0
                                            currentStep={currentStep}
                                            handleStepChange={handleStepChange}
                                            tipoUsina={usinaData.tipoUsina}
                                            formType={formType}
                                        />
                                    ) : (
                                        ''
                                    ),
                            },
                            {
                                title: 'Painéis solares',
                                description:
                                    currentStep === 2 ? (
                                        <FormStep1
                                            currentStep={currentStep}
                                            handleStepChange={handleStepChange}
                                            formInstance={form}
                                            tipoUsina={usinaData.tipoUsina}
                                            formType={formType}
                                        />
                                    ) : (
                                        ''
                                    ),
                            },
                            {
                                title: 'Inversor',
                                description:
                                    currentStep === 3 ? (
                                        <FormStep2
                                            currentStep={currentStep}
                                            handleStepChange={handleStepChange}
                                            formInstance={form}
                                            formType={formType}
                                        />
                                    ) : (
                                        ''
                                    ),
                            },
                            {
                                title: 'Proteções',
                                description:
                                    currentStep === 4 ? (
                                        <FormStep3
                                            currentStep={currentStep}
                                            handleStepChange={handleStepChange}
                                            formInstance={form}
                                            formType={formType}
                                        />
                                    ) : (
                                        ''
                                    ),
                            },
                            {
                                title: 'Identificação de etiquetagem',
                                description:
                                    currentStep === 5 ? (
                                        <FormStep4
                                            currentStep={currentStep}
                                            handleStepChange={handleStepChange}
                                            formType={formType}
                                        />
                                    ) : (
                                        ''
                                    ),
                            },
                            {
                                title: 'Outras informações',
                                description:
                                    currentStep === 6 ? (
                                        <FormStep5
                                            currentStep={currentStep}
                                            handleStepChange={handleStepChange}
                                            formInstance={form}
                                            formType={formType}
                                        />
                                    ) : (
                                        ''
                                    ),
                            },
                            {
                                title: 'Fotos',
                                description:
                                    currentStep === 7 ? (
                                        <FormStep6
                                            currentStep={currentStep}
                                            handleStepChange={handleStepChange}
                                            fileList={fileList}
                                            setFileList={setFileList}
                                            formType={formType}
                                        />
                                    ) : (
                                        ''
                                    ),
                            },
                        ]}
                    />
                </Form>
            )}
        </Card>
    )
}

export default NovaModificarInspecao