import { useContext, useEffect, useState } from 'react'
import { Image } from 'antd'
import { AccountContext } from '../utils/Auth'

interface ImageAuthProps {
    fileKey: string
    width: number
    onPreview?: () => void
}

const ImageAuth = ({ fileKey, width, onPreview }: ImageAuthProps) => {
    const { authenticatedFetchImg } = useContext(AccountContext)
    const [imgUrl, setImgUrl] = useState<string | null>(null)

    useEffect(() => {
        let objectUrl: string | null = null

        const fetchImage = async () => {
            try {
                const encodedKey = encodeURIComponent(fileKey)
                const response = await authenticatedFetchImg(
                    process.env.REACT_APP_SERVER_IP +
                    `/inspecoes/files?fileKey=${encodedKey}`,
                    'GET',
                    null,
                    { 'Cache-Control': 'no-cache' },
                )

                if (!response.ok) throw new Error(`HTTP ${response.status}`)

                const blob = await response.blob()
                objectUrl = URL.createObjectURL(blob)
                setImgUrl(objectUrl)
            } catch (error) {
                console.error('Erro ao carregar imagem:', error)
                setImgUrl(null)
            }
        }

        fetchImage()

        return () => {
            if (objectUrl) URL.revokeObjectURL(objectUrl)
        }
    }, [fileKey])

    return imgUrl ? (
        <Image
            width={width}
            src={imgUrl}
            preview={{
                mask: <span onClick={onPreview}>Visualizar</span>,
            }}
        />
    ) : (
        <div style={{ width, height: width }}>Carregando...</div>
    )
}

export default ImageAuth