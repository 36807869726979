import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Grid, message, Row, Select, Statistic, Table, Tag } from 'antd'
import {
    ClusterOutlined,
    InteractionOutlined,
    PieChartOutlined,
    SmileOutlined,
    ThunderboltOutlined,
} from '@ant-design/icons'
import { AccountContext } from '../utils/Auth'
import { regionaisList } from './Usinas/Usinas'
import { ColumnsType } from 'antd/es/table'

const { Option } = Select

interface DashboardData {
    totalUsinas: number
    totalInspecoes: number
    totalInstalledPower: number
    totalModules: number
    ownerSatisfaction: number
    inspecoesPorRegional: Record<string, number>
    potenciaPorRegional: Record<string, number>
    satisfacaoPorRegional: Record<string, number>
    beneficiosPorRegional: Record<string, Record<string, number>>
}

interface RegionalData {
    key: string
    region: string
    plants: number
    power: number
    inspection: number
    averageOwnerSatisfaction: number
    benefitTypeDistribution: {
        financeiro: number
        ambiental: number
        outro: number
    }
}

const Dashboard: React.FC = () => {
    const [selectedRegion, setSelectedRegion] = useState('all')
    const [loading, setLoading] = useState(true)
    const [dashboardData, setDashboardData] = useState<DashboardData | null>(null)
    const [regionalData, setRegionalData] = useState<RegionalData[]>([])
    const { authenticatedFetch } = useContext(AccountContext)
    const [usinaData, setUsinaData] = useState<{
        totalUsinas: number
        usinasPorRegional: Record<string, number>
    } | null>(null)

    // Hook para identificar breakpoints (ex.: xs, sm, md, etc)
    const screens = Grid.useBreakpoint()

    useEffect(() => {
        const loadData = async () => {
            setLoading(true)
            try {
                const usinaResponse = await authenticatedFetch(
                    `${process.env.REACT_APP_SERVER_IP}/usinas/dashboard`,
                    'GET',
                )
                const usinaData = usinaResponse.ok
                    ? await usinaResponse.json()
                    : null

                const inspecaoResponse = await authenticatedFetch(
                    `${process.env.REACT_APP_SERVER_IP}/inspecoes/dashboard`,
                    'GET',
                )
                const inspecaoData = inspecaoResponse.ok
                    ? await inspecaoResponse.json()
                    : null

                if (usinaData && inspecaoData) {
                    setUsinaData(usinaData)

                    const regionalEntries = Object.entries(usinaData.usinasPorRegional || {}).map(
                        ([region, plants]) => ({
                            key: region,
                            region,
                            plants: Number(plants),
                            power: (inspecaoData.potenciaPorRegional?.[region] || 0) / 1000,
                            inspection: inspecaoData.inspecoesPorRegional?.[region] || 0,
                            averageOwnerSatisfaction: inspecaoData.satisfacaoPorRegional?.[region] || 0,
                            benefitTypeDistribution: {
                                financeiro: inspecaoData.beneficiosPorRegional?.[region]?.FINANCEIRO || 0,
                                ambiental: inspecaoData.beneficiosPorRegional?.[region]?.AMBIENTAL || 0,
                                outro: inspecaoData.beneficiosPorRegional?.[region]?.OUTRO || 0,
                            },
                        }),
                    )

                    setDashboardData({
                        totalUsinas: usinaData.totalUsinas,
                        totalInspecoes: inspecaoData.totalInspecoes,
                        totalInstalledPower: inspecaoData.potenciaTotalInstalada / 1000,
                        totalModules: inspecaoData.paineisTotaisInstalados,
                        ownerSatisfaction: inspecaoData.satisfacaoMediaProprietario,
                        inspecoesPorRegional: inspecaoData.inspecoesPorRegional,
                        potenciaPorRegional: inspecaoData.potenciaPorRegional,
                        satisfacaoPorRegional: inspecaoData.satisfacaoPorRegional,
                        beneficiosPorRegional: inspecaoData.beneficiosPorRegional,
                    })

                    setRegionalData(regionalEntries)
                }
            } catch (error) {
                message.error('Erro ao processar dados do dashboard')
                console.error('Erro:', error)
            } finally {
                setLoading(false)
            }
        }

        loadData()
    }, [authenticatedFetch])

    const columns: ColumnsType<RegionalData> = [
        {
            title: 'Regional',
            key: 'region',
            responsive: ['lg'],
            render: (_: any, record: any) => (
                <div>
                    {regionaisList?.find(
                        (regional) => regional.value === record.region,
                    )?.label || 'Regional não encontrada'}
                </div>
            ),
        },
        {
            title: 'Usinas',
            dataIndex: 'plants',
            key: 'plants',
            sorter: (a: RegionalData, b: RegionalData) => a.plants - b.plants,
        },
        {
            title: 'Inspeções',
            dataIndex: 'inspection',
            key: 'inspection',
            sorter: (a: RegionalData, b: RegionalData) => a.inspection - b.inspection,
        },
        {
            title: 'Potência (MW)',
            dataIndex: 'power',
            key: 'power',
            sorter: (a: RegionalData, b: RegionalData) => a.power - b.power,
            render: (power: number) => `${power.toFixed(1)} MW`,
        },
        {
            title: 'Satisfação',
            dataIndex: 'averageOwnerSatisfaction',
            key: 'averageOwnerSatisfaction',
            sorter: (a: RegionalData, b: RegionalData) =>
                a.averageOwnerSatisfaction - b.averageOwnerSatisfaction,
            render: (satisfaction: number) => `${satisfaction.toFixed(1)}/5`,
        },
        {
            title: 'Distribuição de Benefícios',
            key: 'benefitTypeDistribution',
            render: (record: RegionalData) => (
                <div>
                    <Tag color="green">
                        Financeiro: {record.benefitTypeDistribution.financeiro.toFixed(1)}%
                    </Tag>
                    <Tag color="blue">
                        Ambiental: {record.benefitTypeDistribution.ambiental.toFixed(1)}%
                    </Tag>
                    <Tag color="purple">
                        Outro: {record.benefitTypeDistribution.outro.toFixed(1)}%
                    </Tag>
                </div>
            ),
        },
    ]

    return (
        <div className="dashboard-container p-4">
            {/* Dados gerais: para telas pequenas, usamos xs={12} para reduzir o espaço ocupado */}
            <Card title="Dados gerais" loading={loading}>
                <Row gutter={[16, 16]} className="mb-4">
                    <Col xs={12} sm={12} md={8} lg={4}>
                        <Card hoverable>
                            <Statistic
                                title="Usinas totais"
                                value={dashboardData?.totalUsinas || 0}
                                prefix={<ThunderboltOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={4}>
                        <Card hoverable>
                            <Statistic
                                title="Inspeções totais"
                                value={dashboardData?.totalInspecoes || 0}
                                prefix={<InteractionOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={4}>
                        <Card hoverable>
                            <Statistic
                                title="Potência total"
                                value={dashboardData?.totalInstalledPower?.toFixed(1) || 0}
                                suffix="MW"
                                prefix={<ClusterOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={4}>
                        <Card hoverable>
                            <Statistic
                                title="Paineis totais"
                                value={dashboardData?.totalModules || 0}
                                prefix={<PieChartOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={4}>
                        <Card hoverable>
                            <Statistic
                                title="Satisfação média"
                                value={dashboardData?.ownerSatisfaction?.toFixed(1) || 0}
                                suffix="/5"
                                prefix={<SmileOutlined />}
                            />
                        </Card>
                    </Col>
                </Row>
            </Card>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Card title="Distribuição regional" loading={loading}>
                        {/* Para telas pequenas, renderiza o select centralizado abaixo do título */}
                        {!screens.md && (
                            <Row justify="center" style={{ marginBottom: 16 }}>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Filtrar por Região"
                                    onChange={setSelectedRegion}
                                    defaultValue="all"
                                >
                                    <Option value="all">Todas as Regiões</Option>
                                    {usinaData?.usinasPorRegional &&
                                        Object.keys(usinaData.usinasPorRegional).map(region => (
                                            <Option key={region} value={region}>
                                                {region}
                                            </Option>
                                        ))}
                                </Select>
                            </Row>
                        )}
                        {/* Para telas maiores, podemos manter o select no extra (ou omiti-lo, se desejar) */}
                        {screens.md && (
                            <div style={{ marginBottom: 16 }}>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Filtrar por Região"
                                    onChange={setSelectedRegion}
                                    defaultValue="all"
                                >
                                    <Option value="all">Todas as Regiões</Option>
                                    {usinaData?.usinasPorRegional &&
                                        Object.keys(usinaData.usinasPorRegional).map(region => (
                                            <Option key={region} value={region}>
                                                {region}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        )}
                        <Table
                            columns={columns}
                            dataSource={
                                selectedRegion === 'all'
                                    ? regionalData
                                    : regionalData.filter(region => region.region === selectedRegion)
                            }
                            pagination={{
                                pageSize: 5,
                            }}
                            scroll={{ x: 'max-content' }}
                            loading={loading}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Dashboard