import React, { useContext, useEffect, useState } from 'react'
import { App, Button, Card, Descriptions, Drawer, Empty, Image, Space, Typography } from 'antd'
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useWindowDimensions from '../../../../utils/useWindowDimensions'
import ImageAuth from '../../../../components/ImageAuth'
import { AccountContext } from '../../../../utils/Auth'
import VisualizarComponenteDrawer from '../../../Componentes/VisualizarComponenteDrawer'
import questions from './Questions'
import { Document, Image as PDFImage, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'

import napiLogo from '../../../../images/napi_logo_horizontal_white.svg'

type Props = {
    visualizarInspecaoDrawerOpen: boolean
    setVisualizarInspecaoDrawerOpen: (value: boolean) => void
    idInspecao: number
    tipoUsina: string
    usinaData: any
}

const VisualizarInspecaoDrawer = (props: Props) => {
    const loadedQuestions = questions()

    const { message } = App.useApp()
    const { id } = useParams()
    const { authenticatedFetch } = useContext(AccountContext)
    const { width, isMobileLayout } = useWindowDimensions()
    const navigate = useNavigate()
    const location = useLocation()
    const [instruidoLimpezaPaineis, setInstruidoLimpezaPaineis] =
        useState<boolean>(false)
    const [formType, setFormType] = useState<string>('PROD')
    const [visualizarComponenteDrawerOpen, setVisualizarComponenteDrawerOpen] =
        useState(false)
    const [imagePreviewVisible, setImagePreviewVisible] = useState(false)
    const [
        visualizarComponenteDrawerModelo,
        setVisualizarComponenteDrawerModelo,
    ] = useState('')
    const [visualizarComponenteDrawerTipo, setVisualizarComponenteDrawerTipo] =
        useState<'painel' | 'transformador' | 'inversor'>('painel')
    const [data, setData] = useState<any>({})

    useEffect(() => {
        if (!location.pathname.includes('visualizarInspecao')) {
            props.setVisualizarInspecaoDrawerOpen(false)
        }
        /*        if (!location.pathname.includes('preview')) {
                    setImagePreviewVisible(false)
                }*/
    }, [location])

    useEffect(() => {
        if (props.idInspecao !== -1) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                '/inspecoes?id=' +
                props.idInspecao,
                'GET',
            )
                .then(
                    (res: {
                        ok: any
                        status: number
                        json: () => Promise<any[]>
                    }) => {
                        if (res.ok) {
                            res.json().then((data: any) => {
                                let formattedData: any = data
                                formattedData['dataCriacao'] = getFormattedDate(
                                    data.dataCriacao,
                                )
                                formattedData['ultimaModificacao'] =
                                    getFormattedDate(data.ultimaModificacao)
                                formattedData['potenciaTotalInstaladaCC'] =
                                    data['potenciaTotalInstaladaCC'] + ' kWp'
                                formattedData['desvioAzimutal'] =
                                    data['desvioAzimutal'] + '°'
                                formattedData['anguloInclinacaoPaineis'] =
                                    data['anguloInclinacaoPaineis'] + '°'

                                setData(formattedData)

                                data.proprietarioInstruidoLimpezaPaineis ===
                                true
                                    ? setInstruidoLimpezaPaineis(true)
                                    : setInstruidoLimpezaPaineis(false)

                                if (data.formType !== undefined)
                                    setFormType(data.formType)
                            })
                        } else if (res.status === 404) {
                            message.error({
                                key: 'inspecaoFetchError',
                                content: 'Inspeção não encontrada',
                                duration: 6,
                            })
                        }
                    },
                )
                .catch(() => {
                    message.error({
                        key: 'inspecaoFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 6,
                    })
                })
        }
    }, [props.idInspecao])

    const getComponenteWithModelo = (componente: string, modelo: string) => {
        let requestUrl =
            process.env.REACT_APP_SERVER_IP +
            '/componentes/' +
            componente +
            `/listar?pagina=0`

        requestUrl += `&modelo=${modelo}`

        authenticatedFetch(requestUrl, 'GET')
            .then((res: { ok: any; json: () => Promise<any[]> }) => {
                if (res.ok) {
                    res.json().then((data: any) => {
                        return data.content[0]
                    })
                }
            })
            .catch((e: any) => {
                message.error({
                    key: 'componentesFetchError',
                    content: 'Erro ao tentar contactar o servidor',
                    duration: 8,
                })
            })
    }
    const getFormattedDate = (date: string) => {
        return new Date(date).toLocaleDateString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
    }

    const descriptionItemRespostaSimples = (
        fieldName: string,
        pergunta: string,
    ) => {
        return (
            <Descriptions.Item label={pergunta} span={2}>
                <b>Resposta:</b> {getResponseFieldValue(data[fieldName])}
                <br />
                {data[fieldName] !== null && (
                    <>
                        <b>Fonte de informação:</b>{' '}
                        {getFormattedFonteInformacao(data[fieldName + 'FI'])}
                        <br />
                        <b>Observações:</b>{' '}
                        {data[fieldName + 'Obs']
                            ? data[fieldName + 'Obs']
                            : 'Não há'}
                    </>
                )}
            </Descriptions.Item>
        )
    }

    const descriptionItemComponente = (
        componentes: Array<{ quantidade: number; modelo: string }>,
        tipoComponente: 'painel' | 'inversor' | 'transformador',
    ) => {
        let label

        switch (tipoComponente) {
            case 'painel':
                label = 'Painéis'
                break
            case 'inversor':
                label = 'Inversores'
                break
            case 'transformador':
                label = 'Transformadores'
                break
            default:
                label = ''
                break
        }

        return (
            <Descriptions.Item label={label} span={2}>
                {componentes &&
                    componentes.length > 0 &&
                    componentes.map((componente, index) => (
                        <div key={`${componente.modelo}-${index}`}>
                            <b>Quantidade: </b>{componente.quantidade} un.{' '}
                            <b>Modelo: </b>
                            <Typography.Link
                                onClick={(e) => {
                                    setVisualizarComponenteDrawerTipo(
                                        tipoComponente,
                                    )
                                    setVisualizarComponenteDrawerModelo(componente.modelo)
                                    navigate(
                                        'visualizarInspecao/visualizarComponente/',
                                    )
                                    setVisualizarComponenteDrawerOpen(true)
                                }}
                            >
                                {componente.modelo}
                                <SearchOutlined style={{ marginLeft: 6 }} />
                            </Typography.Link>
                        </div>
                    ))}
            </Descriptions.Item>
        )
    }

    const getResponseFieldValue = (response: any) => {
        if (response === true) {
            return 'Sim'
        } else if (response === false) {
            return 'Não'
        } else if (response === null) {
            return 'Não avaliado'
        } else if (typeof response === 'string' && response.match(/^E[0-9]$/)) {
            return response[1]
        } else {
            return response
        }
    }

    const getFormattedFonteInformacao = (fonte: string) => {
        switch (fonte) {
            case 'IDR':
                return 'IDR'
            case 'PROJETO_ELETRICO':
                return 'Projeto elétrico'
            case 'VISITA':
                return 'Observado durante visita'
            case 'PRODUTOR':
                return 'Produtor'
            default:
                return fonte
        }
    }

    const styles = StyleSheet.create({
        pageCount: {
            fontSize: 10,
            //marginRight: 20,
        },
        page: {
            //backgroundColor: '#E4E4E4',
            padding: 30,
        },
        section: {},
        flex: {
            flexDirection: 'row',
            textAlign: 'left',
        },
        sectionTitle: {
            fontSize: 13,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: 'grey',
            marginBottom: '6px',
            marginTop: '16px',
        },
        sectionData: {
            fontSize: 11,
            marginBottom: '3px',
        },
        sectionDataLeft: {
            fontSize: 11,
            flexBasis: '50%',
        },
        title: {
            flexGrow: 1,
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 25,
        },
        headerImage: {
            width: '160px',
            minHeight: '36px',
        },
        questionView: {
            marginBottom: '12px',
        },
        questionText: {
            fontSize: 11,
            marginBottom: '3px',
        },
    })

    const GeneratePDF = () => (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <View style={styles.header} fixed>
                    <PDFImage src={napiLogo} style={styles.headerImage} />
                    <Text style={styles.title} render={() => ''} />
                    <Text
                        style={styles.pageCount}
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                </View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Dados gerais</Text>
                    <View style={styles.flex}>
                        <Text
                            style={styles.sectionDataLeft}
                        >{`Data da inspeção: ${data.dataCriacao}`}</Text>
                        <Text style={styles.sectionData}>
                            Última modificação: {data.ultimaModificacao}
                        </Text>
                    </View>
                    <Text style={styles.sectionData}>
                        {`Inspetor: ${
                            data.inspetor ? data.inspetor.nome : 'null'
                        } (${data.inspetor ? data.inspetor.email : 'null'})`}
                    </Text>
                    <Text style={styles.sectionData}>
                        Tipo de inspeção:{' '}
                        {formType === 'PROD'
                            ? 'Produtor'
                            : formType === 'EXT'
                                ? 'Extensionista'
                                : formType === 'TECNICA'
                                    ? 'Técnico'
                                    : ''}
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Dados da usina</Text>

                    <View style={styles.flex}>
                        <Text style={styles.sectionDataLeft}>
                            {`Proprietário: ${props.usinaData.proprietario}`}
                        </Text>
                        <Text style={styles.sectionData}>
                            Tipo de produção: {props.usinaData.tipoProducao}
                        </Text>
                    </View>

                    <View style={styles.flex}>
                        <Text style={styles.sectionData}>
                            Contatos: {props.usinaData.telefone1}
                            {props.usinaData.telefone2
                                ? props.usinaData.telefone2
                                : ''}
                        </Text>
                    </View>

                    <View style={styles.flex}>
                        <Text style={styles.sectionDataLeft}>
                            Cidade: {props.usinaData.cidade}
                        </Text>
                        <Text style={styles.sectionData}>
                            Endereço: {props.usinaData.endereco}
                        </Text>
                    </View>
                </View>

                <View wrap>
                    <Text style={styles.sectionTitle}>Infraestrutura</Text>
                    {[1, 2, 3, 4, 5, 6, 7].map((e) => {
                        let show =
                            //@ts-ignore
                            loadedQuestions['q' + e].allowed.includes(formType)

                        return (
                            show && (
                                <View style={styles.questionView} wrap={false}>
                                    <Text style={styles.questionText}>
                                        {/*//@ts-ignore */}•{' '}
                                        {loadedQuestions['q' + e].description}
                                    </Text>
                                    <Text style={styles.questionText}>
                                        Condição:{' '}
                                        {getResponseFieldValue(
                                            /*//@ts-ignore */
                                            data[loadedQuestions['q' + e].field_name],
                                        )}
                                    </Text>
                                    {/*@ts-ignore*/}
                                    {data[loadedQuestions['q' + e].field_name] !==
                                        null && (
                                            <Text style={styles.questionText}>
                                                Fonte da informação:{' '}
                                                {getFormattedFonteInformacao(
                                                    data[
                                                        /*//@ts-ignore */
                                                    loadedQuestions['q' + e]
                                                        .field_name + 'FI'
                                                        ],
                                                )}
                                            </Text>
                                        )}
                                </View>
                            )
                        )
                    })}
                </View>

                <View wrap>
                    <Text style={styles.sectionTitle}>Painéis solares</Text>
                    <View style={styles.questionView} wrap={false}>
                        <Text style={styles.questionText}>
                            {data &&
                                data['paineis'] &&
                                data['paineis'].map((painel: any, index: any) => (
                                    <React.Fragment key={index}>
                                        {`${painel.quantidade} un. modelo ${painel.modelo}`}
                                        {index < data['paineis'].length - 1 ? '; ' : ''}
                                    </React.Fragment>
                                ))}
                        </Text>
                    </View>
                    {[8, 12, 13, 14, 15].map((e) => {
                        let show =
                            //@ts-ignore
                            loadedQuestions['q' + e].allowed.includes(formType)

                        return (
                            show && (
                                <View style={styles.questionView} wrap={false}>
                                    <Text style={styles.questionText}>
                                        {/*//@ts-ignore */}•{' '}
                                        {loadedQuestions['q' + e].description}
                                    </Text>
                                    <Text style={styles.questionText}>
                                        Condição:{' '}
                                        {getResponseFieldValue(
                                            /*//@ts-ignore */
                                            data[loadedQuestions['q' + e].field_name],
                                        )}
                                    </Text>
                                    {/*@ts-ignore*/}
                                    {data[loadedQuestions['q' + e].field_name] !==
                                        null && (
                                            <Text style={styles.questionText}>
                                                Fonte da informação:{' '}
                                                {getFormattedFonteInformacao(
                                                    data[
                                                        /*//@ts-ignore */
                                                    loadedQuestions['q' + e]
                                                        .field_name + 'FI'
                                                        ],
                                                )}
                                            </Text>
                                        )}
                                </View>
                            )
                        )
                    })}
                    {data.anguloPaineisExtras &&
                        data.anguloPaineisExtras.length > 0 && (
                            <>
                                <Text style={styles.questionText}>
                                    • Ângulo dos demais painéis
                                    {data.anguloPaineisExtras.map(
                                        (
                                            e: {
                                                desvioAzimutal: string
                                                anguloInclinacaoPaineis: string
                                            },
                                            idx: number,
                                        ) => {
                                            return (
                                                <>
                                                    <Text
                                                        style={
                                                            styles.questionText
                                                        }
                                                    >
                                                        Desvio Azimutal:{' '}
                                                        {e.desvioAzimutal}°
                                                    </Text>
                                                    <Text
                                                        style={
                                                            styles.questionText
                                                        }
                                                    >
                                                        Ângulo de inclinação:{' '}
                                                        {
                                                            e.anguloInclinacaoPaineis
                                                        }
                                                        °
                                                    </Text>
                                                </>
                                            )
                                        },
                                    )}
                                </Text>
                            </>
                        )}

                    {[16, 17, 18, 19, 20, 21].map((e) => {
                        let show =
                            //@ts-ignore
                            loadedQuestions['q' + e].allowed.includes(formType)

                        return (
                            show && (
                                <View style={styles.questionView} wrap={false}>
                                    <Text style={styles.questionText}>
                                        {/*//@ts-ignore */}•{' '}
                                        {loadedQuestions['q' + e].description}
                                    </Text>
                                    <Text style={styles.questionText}>
                                        Condição:{' '}
                                        {getResponseFieldValue(
                                            /*//@ts-ignore */
                                            data[loadedQuestions['q' + e].field_name],
                                        )}
                                    </Text>
                                    {/*@ts-ignore*/}
                                    {data[loadedQuestions['q' + e].field_name] !==
                                        null && (
                                            <Text style={styles.questionText}>
                                                Fonte da informação:{' '}
                                                {getFormattedFonteInformacao(
                                                    data[
                                                        /*//@ts-ignore */
                                                    loadedQuestions['q' + e]
                                                        .field_name + 'FI'
                                                        ],
                                                )}
                                            </Text>
                                        )}
                                </View>
                            )
                        )
                    })}
                </View>

                <View wrap>
                    <Text style={styles.sectionTitle}>Inversores</Text>
                    <View style={styles.questionView} wrap={false}>
                        <Text style={styles.questionText}>
                            {data &&
                                data['inversores'] &&
                                data['inversores'].map((inversor: any, index: any) => (
                                    <React.Fragment key={index}>
                                        {`${inversor.quantidade} un. modelo ${inversor.modelo}`}
                                        {index < data['inversores'].length - 1 ? '; ' : ''}
                                    </React.Fragment>
                                ))}
                        </Text>
                    </View>
                    {[22, 26, 27, 28, 29].map((e) => {
                        let show =
                            //@ts-ignore
                            loadedQuestions['q' + e].allowed.includes(formType)

                        return (
                            show && (
                                <View style={styles.questionView} wrap={false}>
                                    <Text style={styles.questionText}>
                                        {/*//@ts-ignore */}•{' '}
                                        {loadedQuestions['q' + e].description}
                                    </Text>
                                    <Text style={styles.questionText}>
                                        Condição:{' '}
                                        {getResponseFieldValue(
                                            /*//@ts-ignore */
                                            data[loadedQuestions['q' + e].field_name],
                                        )}
                                    </Text>
                                    {/*@ts-ignore*/}
                                    {data[loadedQuestions['q' + e].field_name] !==
                                        null && (
                                            <Text style={styles.questionText}>
                                                Fonte da informação:{' '}
                                                {getFormattedFonteInformacao(
                                                    data[
                                                        /*//@ts-ignore */
                                                    loadedQuestions['q' + e]
                                                        .field_name + 'FI'
                                                        ],
                                                )}
                                            </Text>
                                        )}
                                </View>
                            )
                        )
                    })}
                </View>

                {formType === 'TECNICA' && (
                    <View wrap>
                        <Text style={styles.sectionTitle}>Proteções</Text>
                        {[30, 31, 32, 33, 34].map((e) => {
                            let show =
                                //@ts-ignore
                                loadedQuestions['q' + e].allowed.includes(formType)

                            return (
                                show && (
                                    <View
                                        style={styles.questionView}
                                        wrap={false}
                                    >
                                        <Text style={styles.questionText}>
                                            {/*//@ts-ignore */}•{' '}
                                            {loadedQuestions['q' + e].description}
                                        </Text>
                                        <Text style={styles.questionText}>
                                            Condição:{' '}
                                            {getResponseFieldValue(
                                                data[
                                                    /*//@ts-ignore */
                                                    loadedQuestions['q' + e]
                                                        .field_name
                                                    ],
                                            )}
                                        </Text>
                                        {/*@ts-ignore*/}
                                        {data[loadedQuestions['q' + e].field_name] !==
                                            null && (
                                                <Text style={styles.questionText}>
                                                    Fonte da informação:{' '}
                                                    {getFormattedFonteInformacao(
                                                        data[
                                                            /*//@ts-ignore */
                                                        loadedQuestions['q' + e]
                                                            .field_name + 'FI'
                                                            ],
                                                    )}
                                                </Text>
                                            )}
                                    </View>
                                )
                            )
                        })}
                    </View>
                )}

                {formType === 'TECNICA' && (
                    <View wrap>
                        <Text style={styles.sectionTitle}>
                            Identificação e etiquetagem
                        </Text>
                        {/* Inversor aqui */}
                        {[35, 36, 37, 38, 39, 40].map((e: any) => {
                            let show =
                                //@ts-ignore
                                loadedQuestions['q' + e].allowed.includes(formType)

                            return (
                                show && (
                                    <View
                                        style={styles.questionView}
                                        wrap={false}
                                    >
                                        <Text style={styles.questionText}>
                                            {/*//@ts-ignore */}•{' '}
                                            {loadedQuestions['q' + e].description}
                                        </Text>
                                        <Text style={styles.questionText}>
                                            Condição:{' '}
                                            {getResponseFieldValue(
                                                data[
                                                    /*//@ts-ignore */
                                                    loadedQuestions['q' + e]
                                                        .field_name
                                                    ],
                                            )}
                                        </Text>
                                        {/*@ts-ignore*/}
                                        {data[loadedQuestions['q' + e].field_name] !==
                                            null && (
                                                <Text style={styles.questionText}>
                                                    Fonte da informação:{' '}
                                                    {getFormattedFonteInformacao(
                                                        data[
                                                            /*//@ts-ignore */
                                                        loadedQuestions['q' + e]
                                                            .field_name + 'FI'
                                                            ],
                                                    )}
                                                </Text>
                                            )}
                                    </View>
                                )
                            )
                        })}
                    </View>
                )}

                <View wrap>
                    <Text style={styles.sectionTitle}>Outras informações</Text>
                    {[
                        41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
                        55, 56, 57, 58,
                    ].map((e) => {
                        let show =
                            //@ts-ignore
                            loadedQuestions['q' + e].allowed.includes(formType)

                        return (
                            show && (
                                <View style={styles.questionView} wrap={false}>
                                    <Text style={styles.questionText}>
                                        {/*//@ts-ignore */}•{' '}
                                        {loadedQuestions['q' + e].description}
                                    </Text>
                                    <Text style={styles.questionText}>
                                        Condição:{' '}
                                        {getResponseFieldValue(
                                            /*//@ts-ignore */
                                            data[loadedQuestions['q' + e].field_name],
                                        )}
                                    </Text>
                                    {/*@ts-ignore*/}
                                    {data[loadedQuestions['q' + e].field_name] !==
                                        null && (
                                            <Text style={styles.questionText}>
                                                Fonte da informação:{' '}
                                                {getFormattedFonteInformacao(
                                                    data[
                                                        /*//@ts-ignore */
                                                    loadedQuestions['q' + e]
                                                        .field_name + 'FI'
                                                        ],
                                                )}
                                            </Text>
                                        )}
                                </View>
                            )
                        )
                    })}
                </View>

                {/*<View wrap>*/}
                {/*    {data.fotos &&*/}
                {/*        data.fotos.length > 0 &&*/}
                {/*        data.fotos.map((url: string) => {*/}
                {/*            return (*/}
                {/*                <PDFImage*/}
                {/*                    key={url}*/}
                {/*                    style={{ width: 100 }}*/}
                {/*                    src={{*/}
                {/*                        uri: url,*/}
                {/*                        method: 'GET',*/}
                {/*                        headers: {},*/}
                {/*                        body: '',*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            )*/}
                {/*        })}*/}
                {/*</View>*/}
            </Page>
        </Document>
    )

    const handlePreview = (fileKey: string) => {
        navigate(`visualizarInspecao?previewImage=${encodeURIComponent(fileKey)}`, { replace: true })
    }

    return (
        <>
            <VisualizarComponenteDrawer
                open={visualizarComponenteDrawerOpen}
                setOpen={setVisualizarComponenteDrawerOpen}
                id={-1}
                modelo={visualizarComponenteDrawerModelo}
                tipoComponente={visualizarComponenteDrawerTipo}
                setComponenteModificado={() => {
                }}
                readOnlyMode={true}
            />
            <Drawer
                style={{ position: 'relative' }}
                width={width < 640 ? '100%' : 640}
                placement="right"
                closable={true}
                onClose={(e) => {
                    navigate(-1)
                }}
                open={props.visualizarInspecaoDrawerOpen}
                title={
                    'Visualizar inspeção' +
                    (formType === 'PROD'
                        ? ' (Produtor)'
                        : formType === 'EXT'
                            ? ' (Extensionista)'
                            : formType === 'TECNICA'
                                ? ' (Técnico)'
                                : '')
                }
                extra={
                    data && (
                        <PDFDownloadLink
                            document={<GeneratePDF />}
                            fileName={'FormularioUsina' + id + '.pdf'}
                        >
                            <Button type="primary" icon={<FilePdfOutlined />}>
                                Exportar
                            </Button>
                        </PDFDownloadLink>
                    )
                }
            >
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <Descriptions
                        title="Dados gerais"
                        bordered
                        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        size={'middle'}
                        layout={isMobileLayout ? 'vertical' : 'horizontal'}
                    >
                        <Descriptions.Item label="Data da inspeção">
                            {data.dataCriacao}
                        </Descriptions.Item>
                        <Descriptions.Item label="Última modificação">
                            {data.ultimaModificacao}
                        </Descriptions.Item>
                        <Descriptions.Item label="Inspetor" span={2}>
                            <Typography.Text>
                                {data.inspetor && data.inspetor.nome}{' '}
                                <Typography.Text type={'secondary'}>
                                    ({data.inspetor && data.inspetor.email})
                                </Typography.Text>
                            </Typography.Text>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                        title={
                            <div style={{ marginTop: 20 }}>Infraestrutura</div>
                        }
                        bordered
                        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        size={'middle'}
                        layout={'vertical'}
                    >
                        {[1, 2, 3, 4, 5, 6, 7].map((e) => {
                            let show =
                                //@ts-ignore
                                loadedQuestions['q' + e].allowed.includes(formType)

                            return (
                                show &&
                                descriptionItemRespostaSimples(
                                    //@ts-ignore
                                    loadedQuestions['q' + e].field_name,
                                    //@ts-ignore
                                    loadedQuestions['q' + e].description,
                                )
                            )
                        })}
                    </Descriptions>
                    <Descriptions
                        title={
                            <div style={{ marginTop: 20 }}>Painéis solares</div>
                        }
                        bordered
                        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        size={'middle'}
                        layout={'vertical'}
                    >
                        {descriptionItemComponente(data.paineis, 'painel')}
                        {[8, 12, 13, 14, 15].map((e) => {
                            let show =
                                //@ts-ignore
                                loadedQuestions['q' + e].allowed.includes(formType)

                            return (
                                show &&
                                descriptionItemRespostaSimples(
                                    //@ts-ignore
                                    loadedQuestions['q' + e].field_name,
                                    //@ts-ignore
                                    loadedQuestions['q' + e].description,
                                )
                            )
                        })}

                        {data.anguloPaineisExtras &&
                            data.anguloPaineisExtras.length > 0 && (
                                <Descriptions.Item
                                    label={'Ângulos dos demais painéis'}
                                    span={2}
                                >
                                    {data.anguloPaineisExtras.map(
                                        (
                                            e: {
                                                desvioAzimutal: string
                                                anguloInclinacaoPaineis: string
                                            },
                                            idx: number,
                                        ) => {
                                            return (
                                                <>
                                                    <b>Desvio Azimutal:</b>{' '}
                                                    {e.desvioAzimutal}°
                                                    <br />
                                                    <b>
                                                        Ângulo de inclinação:
                                                    </b>{' '}
                                                    {e.anguloInclinacaoPaineis}°
                                                    <br />
                                                    {idx !=
                                                        data.anguloPaineisExtras
                                                            .length -
                                                        1 && <br />}
                                                </>
                                            )
                                        },
                                    )}
                                </Descriptions.Item>
                            )}

                        {[16, 17, 18, 19, 20, 21].map((e) => {
                            let show =
                                //@ts-ignore
                                loadedQuestions['q' + e].allowed.includes(formType)

                            return (
                                show &&
                                descriptionItemRespostaSimples(
                                    //@ts-ignore
                                    loadedQuestions['q' + e].field_name,
                                    //@ts-ignore
                                    loadedQuestions['q' + e].description,
                                )
                            )
                        })}
                    </Descriptions>
                    <Descriptions
                        title={<div style={{ marginTop: 20 }}>Inversores</div>}
                        bordered
                        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        size={'middle'}
                        layout={'vertical'}
                    >
                        {descriptionItemComponente(data.inversores, 'inversor')}
                        {[22, 26, 27, 28, 29].map((e) => {
                            let show =
                                //@ts-ignore
                                loadedQuestions['q' + e].allowed.includes(formType)

                            return (
                                show &&
                                descriptionItemRespostaSimples(
                                    //@ts-ignore
                                    loadedQuestions['q' + e].field_name,
                                    //@ts-ignore
                                    loadedQuestions['q' + e].description,
                                )
                            )
                        })}
                        {props.tipoUsina === 'COBERTURA' &&
                            descriptionItemRespostaSimples(
                                'modulosLocalizadosMesmaAreaTelhado',
                                'Para usinas de cobertura, os painéis se ' +
                                'localizam na mesma área do telhado?',
                            )}

                        {instruidoLimpezaPaineis &&
                            descriptionItemRespostaSimples(
                                'periodicidadeRecomendadaLimpeza',
                                'Qual foi a periodicidade recomendada de ' +
                                ' limpeza?',
                            )}
                    </Descriptions>
                    {formType === 'TECNICA' && (
                        <Descriptions
                            title={
                                <div style={{ marginTop: 20 }}>Proteções</div>
                            }
                            bordered
                            column={{
                                xxl: 2,
                                xl: 2,
                                lg: 2,
                                md: 1,
                                sm: 1,
                                xs: 1,
                            }}
                            size={'middle'}
                            layout={'vertical'}
                        >
                            {[30, 31, 32, 33, 34].map((e) => {
                                let show =
                                    //@ts-ignore
                                    loadedQuestions['q' + e].allowed.includes(
                                        formType,
                                    )

                                return (
                                    show &&
                                    descriptionItemRespostaSimples(
                                        //@ts-ignore
                                        loadedQuestions['q' + e].field_name,
                                        //@ts-ignore
                                        loadedQuestions['q' + e].description,
                                    )
                                )
                            })}
                        </Descriptions>
                    )}
                    {formType === 'TECNICA' && (
                        <Descriptions
                            title={
                                <div style={{ marginTop: 20 }}>
                                    Identificação e etiquetagem
                                </div>
                            }
                            bordered
                            column={{
                                xxl: 2,
                                xl: 2,
                                lg: 2,
                                md: 1,
                                sm: 1,
                                xs: 1,
                            }}
                            size={'middle'}
                            layout={'vertical'}
                        >
                            {[35, 36, 37, 38, 39, 40].map((e) => {
                                let show =
                                    //@ts-ignore
                                    loadedQuestions['q' + e].allowed.includes(
                                        formType,
                                    )

                                return (
                                    show &&
                                    descriptionItemRespostaSimples(
                                        //@ts-ignore
                                        loadedQuestions['q' + e].field_name,
                                        //@ts-ignore
                                        loadedQuestions['q' + e].description,
                                    )
                                )
                            })}
                        </Descriptions>
                    )}
                    <Descriptions
                        title={
                            <div style={{ marginTop: 20 }}>
                                Outras informações
                            </div>
                        }
                        bordered
                        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
                        size={'middle'}
                        layout={'vertical'}
                    >
                        {[
                            41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
                            54, 55, 56, 57, 58,
                        ].map((e) => {
                            let show =
                                //@ts-ignore
                                loadedQuestions['q' + e].allowed.includes(formType)

                            return (
                                show &&
                                descriptionItemRespostaSimples(
                                    //@ts-ignore
                                    loadedQuestions['q' + e].field_name,
                                    //@ts-ignore
                                    loadedQuestions['q' + e].description,
                                )
                            )
                        })}

                        <Descriptions.Item
                            label={loadedQuestions['q59'].description}
                            span={2}
                        >
                            {data[loadedQuestions['q59'].field_name] !== null
                                ? data[loadedQuestions['q59'].field_name]
                                : 'Não há'}
                        </Descriptions.Item>
                    </Descriptions>
                    <Card title={'Fotos'} className={'image-preview-inspecao'}>
                        <Image.PreviewGroup
                            preview={{
                                visible: imagePreviewVisible,
                                onVisibleChange: (visible, prevVisible) => {
                                    if (visible) {
                                        setImagePreviewVisible(true)
                                        navigate('visualizarInspecao/preview')
                                    } else {
                                        setImagePreviewVisible(false)
                                        navigate(-1)
                                    }
                                },
                            }}
                        >
                            {data.fotosKeys && data.fotosKeys.length > 0 ? (
                                data.fotosKeys.map((fileKey: string) => (
                                    <ImageAuth
                                        fileKey={fileKey}
                                        width={100}
                                        onPreview={() => handlePreview(fileKey)}
                                    />
                                ))
                            ) : (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={'Não há fotos'}
                                    style={{
                                        margin: '16px auto',
                                        width: '100%',
                                        maxWidth: '300px',
                                    }}
                                />
                            )}
                        </Image.PreviewGroup>
                    </Card>
                </Space>
            </Drawer>
        </>
    )
}

export default VisualizarInspecaoDrawer