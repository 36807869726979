import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, InputNumber, message, Row, Select, Space, Typography } from 'antd'
import { MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { useDebounce } from 'use-debounce'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import VisualizarComponenteDrawer from '../../../../Componentes/VisualizarComponenteDrawer'
import NovoComponenteInspecaoDrawer from './NovoComponenteInspecaoDrawer'
import { AccountContext } from '../../../../../utils/Auth'
import { useLocalDb } from '../../../../../utils/useLocalDb'
import usePWAInstall from '../../../../../utils/usePWAInstall'

type ComponenteSelectProps = {
    tipoComponente: 'painel' | 'inversor' | 'transformador';
    formInstance: any;
    formType: string;
    setQuantidadeTotal?: React.Dispatch<React.SetStateAction<number>>;
    setPotenciaTotal?: React.Dispatch<React.SetStateAction<number>>;
};

const ComponenteSelect: React.FC<ComponenteSelectProps> = (props) => {
    const [novoComponenteDrawerOpen, setNovoComponenteDrawerOpen] = useState(false)
    const [fabricantes, setFabricantes] = useState<string[]>([])
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [modelos, setModelos] = useState<string[]>([])
    const [modelo, setModelo] = useState<string>('')
    const [modeloDebounce] = useDebounce(modelo, 500)
    const [messageApi, contextHolder] = message.useMessage()
    const [visualizarComponenteDrawerOpen, setVisualizarComponenteDrawerOpen] = useState(false)
    const [visualizarComponenteDrawerModelo, setVisualizarComponenteDrawerModelo] = useState('')

    // Estados para quantidades e potências
    const [totalQuantidade, setTotalQuantidade] = useState(0)
    const quantidadeTotalRef = useRef<number>(0)
    const [totalPotencia, setTotalPotencia] = useState(0)
    const [potenciasPorModelo, setPotenciasPorModelo] = useState<{ [key: string]: number }>({})

    const { authenticatedFetch } = useContext(AccountContext)
    const navigate = useNavigate()
    const { id, idInspecao } = useParams()
    const { getFabricantesFromLocalDb, getComponentesFromLocalDb, isOnline } = useLocalDb()
    const { isInstalled } = usePWAInstall()
    const location = useLocation()

    // Carrega os fabricantes ao montar o componente
    useEffect(() => {
        if (!location.pathname.includes('novoComponente')) {
            getFabricantes()
        }
    }, [props.tipoComponente, location.pathname, isInstalled])

    // Carrega os modelos ao alterar o índice atual
    useEffect(() => {
        getModelos(currentIndex)
    }, [currentIndex, modeloDebounce])

    // Atualiza a soma total das quantidades e potências quando os valores do formulário mudarem
    useEffect(() => {
        const fields = props.formInstance.getFieldValue(getFormFieldName()) || []

        const totalQuantidade = fields.reduce((acc: number, field: any) => acc + (field?.quantidade || 0), 0)
        setTotalQuantidade(totalQuantidade)
        quantidadeTotalRef.current = totalQuantidade

        if (props.setQuantidadeTotal) {
            props.setQuantidadeTotal(totalQuantidade)
        }

        const totalPotencia = fields.reduce((acc: number, field: any) => {
            const modelo = field?.modelo || ''
            const quantidade = field?.quantidade || 0
            const potencia = potenciasPorModelo[modelo] || 0
            return acc + quantidade * potencia
        }, 0)
        setTotalPotencia(totalPotencia)

        if (props.setPotenciaTotal) {
            props.setPotenciaTotal(totalPotencia)
        }

        // Atualizando os valores do formulário que serão enviados ao backend
        if (props.tipoComponente === 'painel') {
            props.formInstance.setFieldsValue({
                quantidadeModulosFotovoltaicos: totalQuantidade, // Atualiza a quantidade no backend
                potenciaTotalInstaladaCC: totalPotencia, // Atualiza a potência total CC no backend
            })
        } else if (props.tipoComponente === 'inversor') {
            props.formInstance.setFieldsValue({
                quantidadeInversores: totalQuantidade, // Atualiza a quantidade de inversores no backend
                potenciaTotalInstaladaCA: totalPotencia, // Atualiza a potência total CA no backend
            })
        }
    }, [props.formInstance.getFieldsValue(), potenciasPorModelo, props.setQuantidadeTotal, props.setPotenciaTotal])

    // Função para buscar um componente com base no modelo e tipo
    const getComponenteWithModelo = async (componente: string, modelo: string) => {
        let requestUrl = process.env.REACT_APP_SERVER_IP + '/componentes/' + componente + `/listar?pagina=0`
        requestUrl += `&modelo=${modelo}`

        try {
            const res = await authenticatedFetch(requestUrl, 'GET')
            if (res.ok) {
                const data = await res.json()
                return data.content[0] // Retorna o primeiro componente encontrado
            }
        } catch (error) {
            messageApi.error('Erro ao buscar o componente do servidor.')
        }
        return null
    }

    const getFabricanteFromForm = (index: number) => {
        const componentes = props.formInstance.getFieldValue(getFormFieldName()) || []
        return componentes[index]?.fabricante || ''
    }

    // Função para lidar com a mudança de modelo no formulário
    const handleModelChange = async (modelo: string, index: number) => {
        const componente = await getComponenteWithModelo(props.tipoComponente, modelo)
        const potencia = componente ? componente.potencia : 0 // Se o componente foi encontrado, pega a potência
        setPotenciasPorModelo((prevPotencias) => ({
            ...prevPotencias,
            [modelo]: potencia, // Atualiza a potência no estado
        }))
    }

    const getFormFieldName = () => {
        switch (props.tipoComponente) {
            case 'painel':
                return 'paineis'
            case 'inversor':
                return 'inversores'
            case 'transformador':
                return 'transformadores'
            default:
                return ''
        }
    }

    const modelosForm = Form.useWatch(getFormFieldName(), props.formInstance)

    const showComponenteDrawer = (index: number) => {
        const modelosForm = props.formInstance.getFieldValue(getFormFieldName())
        const modeloValue = modelosForm && modelosForm[index]?.modelo
        if (!modeloValue) return

        setVisualizarComponenteDrawerModelo(modeloValue)
        setVisualizarComponenteDrawerOpen(true)
    }

    // Carrega os fabricantes
    const getFabricantes = () => {
        if (props.tipoComponente) {
            authenticatedFetch(
                `${process.env.REACT_APP_SERVER_IP}/componentes/${props.tipoComponente}/fabricantes`,
                'GET',
            )
                .then((res: { ok: boolean; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setFabricantes(data)
                        })
                    }
                })
                .catch(() => {
                    if (isInstalled) {
                        getFabricantesFromLocalDb(props.tipoComponente).then((data) => setFabricantes(data))
                    } else if (isOnline) {
                        messageApi.error({
                            key: 'componentesFetchError',
                            content: 'Erro ao tentar contactar o servidor',
                            duration: 8,
                        })
                    }
                })
        }
    }

    // Carrega os modelos
    const getModelos = async (currentIndex: number) => {
        const fabricante = getFabricanteFromForm(currentIndex) // Obter do formulário

        let requestUrl = `${process.env.REACT_APP_SERVER_IP}/componentes/${props.tipoComponente}/listar?pagina=0&tamanhoPagina=1000`

        if (fabricante) {
            requestUrl += `&fabricante=${encodeURIComponent(fabricante)}`
        }

        if (modeloDebounce.length > 0) {
            requestUrl += `&modelo=${modeloDebounce}`
        }

        authenticatedFetch(requestUrl, 'GET')
            .then((res: { ok: boolean; json: () => Promise<any[]> }) => {
                if (res.ok) {
                    res.json().then((data: any) => {
                        const modelosList = data.content.map((componente: any) => componente.modelo)
                        setModelos(modelosList)
                    })
                }
            })
            .catch(() => {
                if (isInstalled && !isOnline) {
                    getComponentesFromLocalDb(props.tipoComponente, '', undefined).then((data) => {
                        const modelosList = data.map((componente) => componente.modelo)
                        setModelos(modelosList)
                    })
                }
            })
    }

    return (
        <>
            <VisualizarComponenteDrawer
                open={visualizarComponenteDrawerOpen}
                setOpen={setVisualizarComponenteDrawerOpen}
                id={-1}
                modelo={visualizarComponenteDrawerModelo}
                tipoComponente={props.tipoComponente}
                setComponenteModificado={() => {
                }}
                readOnlyMode={true}
            />
            <Form.List name={getFormFieldName()}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <div key={key}>
                                <Space>
                                    <b>Modelo {index + 1}</b>
                                    {props.tipoComponente === 'transformador' && (
                                        <Typography.Text type="secondary">(opcional)</Typography.Text>
                                    )}
                                    {fields.length > 1 && (
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    )}
                                </Space>
                                <Row gutter={16}>
                                    <Col xs={24} lg={11} xl={11}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'fabricante']}
                                            rules={[
                                                {
                                                    required: props.tipoComponente !== 'transformador',
                                                    message: 'Por favor selecione um fabricante',
                                                },
                                            ]}
                                        >
                                            <Select
                                                allowClear
                                                style={{ width: '100%', marginBottom: 16 }}
                                                showSearch
                                                placeholder="Fabricante"
                                                options={fabricantes.map((fabricante) => ({
                                                    label: fabricante,
                                                    value: fabricante,
                                                }))}
                                                onChange={(value) => {
                                                    // Resetar modelo ao mudar fabricante
                                                    props.formInstance.setFieldsValue({
                                                        [getFormFieldName()]: [
                                                            ...props.formInstance.getFieldValue(getFormFieldName()).map(
                                                                (item: any, idx: number) =>
                                                                    idx === index ? {
                                                                        ...item,
                                                                        modelo: undefined,
                                                                    } : item,
                                                            ),
                                                        ],
                                                    })
                                                    setCurrentIndex(index)
                                                    getModelos(index)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={21} lg={11} xl={11}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'modelo']}
                                            rules={
                                                props.tipoComponente !== 'transformador'
                                                    ? [{ required: true, message: 'Por favor selecione um modelo' }]
                                                    : []
                                            }
                                        >
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                onSearch={setModelo}
                                                onFocus={() => {
                                                    setCurrentIndex(index)
                                                    getModelos(index)
                                                }}
                                                onChange={(value) => handleModelChange(value, index)}
                                                showSearch
                                                placeholder="Modelo"
                                                options={modelos.map((modelo) => ({
                                                    label: modelo,
                                                    value: modelo,
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'quantidade']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Por favor entre com a quantidade de painéis do modelo ${modelosForm && modelosForm[index] ? modelosForm[index].modelo : ''}`,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                min={1}
                                                style={{ width: '100%' }}
                                                placeholder={`Quantidade do modelo ${modelosForm && modelosForm[index] ? modelosForm[index].modelo : ''}`}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={3} lg={1} xl={1}>
                                        <Button
                                            disabled={!modelosForm || !modelosForm[index]}
                                            style={{ minWidth: 32, width: '100%' }}
                                            onClick={() => {
                                                showComponenteDrawer(name)
                                                navigate('visualizarComponente')
                                            }}
                                            icon={<SearchOutlined />}
                                        />
                                    </Col>
                                </Row>
                                {index === fields.length - 1 && (
                                    <Row gutter={16}>
                                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 12 }}
                                             xl={{ span: 12, offset: 12 }}>
                                            <Button
                                                type="primary"
                                                style={{ width: '100%', marginTop: 16 }}
                                                onClick={() => {
                                                    if (idInspecao) {
                                                        navigate(`/app/usinas/${id}/inspecao/${idInspecao}/modificar/novoComponente`)
                                                    } else {
                                                        navigate(`/app/usinas/${id}/inspecao/nova/novoComponente`)
                                                    }
                                                    setNovoComponenteDrawerOpen(true)
                                                }}
                                            >
                                                Novo componente (placas/inversores)
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        ))}
                        <Row>
                            <Col xs={24} lg={24} xl={24}>
                                <Button type="dashed" style={{ width: '100%', marginTop: 16 }} onClick={() => add()}>
                                    Adicionar mais um modelo
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Form.List>

            <NovoComponenteInspecaoDrawer
                open={novoComponenteDrawerOpen}
                setOpen={setNovoComponenteDrawerOpen}
                tipoComponente={props.tipoComponente}
                formInstance={props.formInstance}
            />
            {contextHolder}
        </>
    )
}

export default ComponenteSelect
