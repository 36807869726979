import React from 'react'

const questions = () => ({
    // Infraestrutura
    q1: {
        field_name: 'caboOuConexaoProtegidoExposicaoLuzSolar',
        description: (
            <>
                Os cabos ou conexões estão protegidos da exposição direta da luz
                solar?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q2: {
        field_name: 'eletrodutosVisiveisEmBomEstado',
        description: <>Os eletrodutos visíveis estão em bom estado?</>,
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q3: {
        field_name: 'ausenciaMaFixacaoEletrocalhas',
        description: (
            <>
                As eletrocalhas e/ou eletrodutos aparentam estar devidamente
                fixados?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q4: {
        field_name: 'existeAbaulamentoNosPaineis',
        description: (
            <>
                A estrutura de fixação dos painéis apresenta aspecto plano
                (sem deformação)?
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'boolean',
    },
    q5: {
        field_name: 'corrosaoEstruturaMetalicaUsina',
        description: (
            <>
                A estrutura metálica da usina está sem sinais visíveis
                de corrosão? (Escala
                de 0=nenhuma, 1=pouco a 5=muito)
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'selection',
        options: [
            { value: 'E0', label: '0' },
            { value: 'E1', label: '1' },
            { value: 'E2', label: '2' },
            { value: 'E3', label: '3' },
            { value: 'E4', label: '4' },
            { value: 'E5', label: '5' },
        ],
    },
    q6: {
        field_name: 'desenvolvidoProjetoEstruturasOuIdentFab',
        description: (
            <>
                Foi desenvolvido o projeto das estruturas e/ou suportes
                metálicos ou há identificação do fabricante e catálogo da
                estrutura?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q7: {
        field_name: 'realizadoEstudoSFVEstruturas',
        description: (
            <>
                Para usinas em coberturas/telhados foram realizados estudos da
                contribuição da carga do SFV nas estruturas existentes por
                profissional habilitado?
            </>
        ),
        allowed: ['TECNICA'],
        tipoUsina: 'COBERTURA',
        type: 'boolean',
    },
    // Painéis Solares
    q8: {
        field_name: 'quantidadeModulosFotovoltaicos',
        description: <>Quantidade total de módulos</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'readonlyInteger',
        addonBefore: 'Unidades',
    },
    // q9, q10, q11 e q12 lidadas separadamente em componentes
    q12: {
        field_name: 'potenciaTotalInstaladaCC',
        description: <>Potência Total Instalada CC (Wp)</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'readonlyInteger',
        addonBefore: 'Wp',
    },
    q13: {
        field_name: 'modulosLocalizadosMesmaAguaDoTelhado',
        description: (
            <>
                Para usinas em coberturas/telhados, os painéis se localizam na
                mesma água do telhado ou dispõe de mesma inclinação?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q14: {
        field_name: 'desvioAzimutal',
        description: <>Qual é o desvio azimutal dos painéis? (ângulo)</>,
        allowed: ['TECNICA'],
        type: 'integer',
        addon_after: '°',
    },
    q15: {
        field_name: 'anguloInclinacaoPaineis',
        description: <>Qual o ângulo de inclinação dos painéis? (ângulo)</>,
        allowed: ['TECNICA'],
        type: 'integer',
        addon_after: '°',
    },
    q16: {
        field_name: 'modulosDevidamenteFixadosNasEstruturas',
        description: (
            <>Todos os módulos estão devidamente fixados nas estruturas?</>
        ),
        allowed: ['EXT', 'TECNICA'],
        type: 'boolean',
    },
    q17: {
        field_name: 'parafusosUtilizadosJuncaoModuloApresentamCorrosao',
        description: (
            <>
                Os parafusos utilizados para fixação dos módulos, estão em
                bom estado e sem sinais de corrosão?
            </>
        ),
        allowed: ['EXT', 'TECNICA'],
        type: 'boolean',
    },
    q18: {
        field_name: 'inexistenciaConectoresSoltosOuAbertos',
        description: (
            <>
                Todos os módulos fotovoltaicos estão interligados e com
                conectores adequados?
            </>
        ),
        allowed: ['EXT', 'TECNICA'],
        type: 'boolean',
    },
    q19: {
        field_name: 'condicaoSujidadeModulos',
        description: (
            <>
                Em termos de existência, ou não, de sujidade (sujeira)
                nos módulos fotovoltaicos, qual sua avaliação segundo
                o nível de sujidade? (Escala de 0 = Sem sujidade aparente,
                1=Sujidade leve a 5=Sujidade severa)
            </>
        ),
        allowed: ['EXT', 'TECNICA'],
        type: 'selection',
        options: [
            { value: 'E0', label: '0' },
            { value: 'E1', label: '1' },
            { value: 'E2', label: '2' },
            { value: 'E3', label: '3' },
            { value: 'E4', label: '4' },
            { value: 'E5', label: '5' },
        ],
    },
    q20: {
        field_name: 'semTrincasFissurasNoVidroModuloFotovoltaico',
        description: <>Os módulos estão sem trincas, fissuras ou manchas?</>,
        allowed: ['EXT', 'TECNICA'],
        type: 'boolean',
    },
    q21: {
        field_name: 'ausenciaSombreamentos',
        description: (
            <>Ausência de sombreamento, total ou parcial, sobre os painéis?</>
        ),
        allowed: ['EXT', 'TECNICA'],
        type: 'boolean',
    },

    // Inversor
    q22: {
        field_name: 'quantidadeInversores',
        description: <>Quantidade total de inversores</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'readonlyInteger',
        addonBefore: 'Unidades',
    },
    // q23, q24, q25, q26 lidadadas separadamente
    q26: {
        field_name: 'potenciaTotalInstaladaCA',
        description: <>Potência Total Instalada CA (kW)</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'readonlyInteger',
        addonBefore: 'kW',
    },
    q27: {
        field_name: 'inversorBemFixado',
        description: <>O inversor está bem fixado?</>,
        allowed: ['EXT', 'TECNICA'],
        type: 'boolean',
    },
    q28: {
        field_name: 'ausenciaCabosSoltosInversor',
        description: <>Ausência de cabos soltos no inversor?</>,
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q29: {
        field_name: 'possivelAcessarDadosGeracaoInversor',
        description: <>É possível acessar os dados de geração do inversor?</>,
        allowed: ['EXT', 'TECNICA'],
        type: 'boolean',
    },

    // Proteções
    q30: {
        field_name: 'existeDisjuntorGeralCCUsina',
        description: (
            <>
                Existe disjuntor geral da usina (CC), com as demais proteções
                DPS e fusíveis (Caixa String box)?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q31: {
        field_name: 'existeDisjuntorGeralCAInstalacao',
        description: (
            <>Existe disjuntor geral da instalação (CA) logo após o inversor?</>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q32: {
        field_name: 'existeChaveCCInversor',
        description: (
            <>
                Há uma chave comutadora instalada no lado CC do inversor ou na
                caixa string box?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q33: {
        field_name: 'aterramentoParteCA',
        description: (
            <>
                Foi identificado a conexão de aterramento do inversor (Parte
                CA)?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q34: {
        field_name: 'aterramentoParteCC',
        description: (
            <>
                Foi identificado a conexão de aterramento nas estruturas
                metálicas e módulos fotovoltaicos (Parte CC)?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },

    // Identificação e etiquetagem
    q35: {
        field_name: 'circuitosDispositivosProtecaoChavesTerminaisEtiquetados',
        description: (
            <>
                Todos os circuitos, dispositivos de proteção, chaves e terminais
                estão devidamente identificados e etiquetados?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q36: {
        field_name: 'etiquetasAdvertenciaFixadasPontoInterconexaoRede',
        description: (
            <>
                No quadro ou ponto de interconexão com a rede, logo após a
                conversão de energia CC/CA pelo inversor, há etiquetas fixadas
                de advertência e cuidado com o risco de choque elétrico ou
                geração própria?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q37: {
        field_name: 'procedimentosDesligamentoEmergenciaExibidosLocal',
        description: (
            <>
                Os procedimentos de desligamento de emergência são exibidos no
                local?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q38: {
        field_name: 'etiquetasIdentificacaoCircuitosCabosFixadasEDuraveis',
        description: (
            <>
                Verifica-se etiquetas de identificação dos circuitos nos cabos
                devidamente fixadas e são duráveis?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q39: {
        field_name: 'etiquetasSinaisPolaridadeFixadasDuraveis',
        description: (
            <>
                Nos circuitos CC é possível identificar os sinais de polaridade
                (+ e -)? (etiquetas e cores distintas)
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q40: {
        field_name: 'etiquetaAvisoCaixasJuncaoCC',
        description: (
            <>
                Existe etiqueta de aviso nas caixas de junção C.C. informando
                que os arranjos fotovoltaicos permanecem energizados mesmo após
                seccionamento do inversor?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },

    // Usina em Geral/Outras informações
    q41: {
        field_name: 'entreguePropDocPelaEmpresa',
        description: (
            <>
                Foi entregue ao proprietário a documentação mínima da usina pela
                empresa responsável pela instalação: - Projeto com a Anotação de
                Responsabilidade Técnica (ART); - Memoriais de cálculo e
                descritivo; - Manuais de operação e manutenção. (Escala de
                0=nenhum, 1=Apenas um dos documentos, 2=Apenas dois dos
                documentos, 3=Todos mencionados)
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'selection',
        options: [
            { value: 'E0', label: '0' },
            { value: 'E1', label: '1' },
            { value: 'E2', label: '2' },
            { value: 'E3', label: '3' },
        ],
    },
    q42: {
        field_name: 'visivelEtiquetaGeracaoDistribuida',
        description: (
            <>
                Na caixa de medição da concessionária ou entrada de serviço, há
                etiqueta/placa de advertência de cuidados com a geração própria
                de energia elétrica?
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'boolean',
    },
    q43: {
        field_name: 'proprietarioSatisfeitoComFV',
        description: (
            <>
                Qual a satisfação do proprietário com o sistema fotovoltaico?
                (Escala de 0=nenhuma, 1=pouco a 5=muito)
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'selection',
        options: [
            { value: 'E0', label: '0' },
            { value: 'E1', label: '1' },
            { value: 'E2', label: '2' },
            { value: 'E3', label: '3' },
            { value: 'E4', label: '4' },
            { value: 'E5', label: '5' },
        ],
    },
    q44: {
        field_name: 'beneficiosSistemaFV',
        description: (
            <>Quais os benefícios apontados com o sistema fotovoltaico?</>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'selection',
        options: [
            { value: 'FINANCEIRO', label: 'Financeiro' },
            { value: 'AMBIENTAL', label: 'Ambiental' },
            { value: 'OUTROS', label: 'Outros' },
        ],
    },
    q45: {
        field_name: 'proprietarioRecebeuOrientacoesAcompanharGeracao',
        description: (
            <>
                O proprietário recebeu orientação de como acompanhar a geração
                da usina?
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'boolean',
    },
    q46: {
        field_name: 'periodicidadeAcompanhamentoGeracaoProp',
        description: (
            <>
                O proprietário acompanha a geração da usina constantemente?
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'selection',
        options: [
            { value: 'DIARIA', label: 'Diária' },
            { value: 'SEMANAL', label: 'Semanal' },
            { value: 'MENSAL', label: 'Mensal' },
            { value: 'OUTRA', label: 'Outra' },
        ],
    },
    q47: {
        field_name: 'proprietarioInstruidoLimpezaPaineis',
        description: (
            <>O proprietário foi instruído a realizar a limpeza dos painéis?</>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'boolean',
    },
    q48: {
        field_name: 'periodicidadeRecomendadaLimpeza',
        description: <>Qual foi a periodicidade de limpeza recomendada?</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'selection',
        options: [
            { value: 'SEMANAL', label: 'Semanal' },
            {
                value: 'QUINZENAL',
                label: 'Quinzenal',
            },
            { value: 'MENSAL', label: 'Mensal' },
            {
                value: 'BIMESTRAL',
                label: 'Bimestral',
            },
            {
                value: 'TRIMESTRAL',
                label: 'Trimestral',
            },
            {
                value: 'SEMESTRAL',
                label: 'Semestral',
            },
            { value: 'ANUAL', label: 'Anual' },
            { value: 'BIENAL', label: 'Bienal' },
            { value: 'OUTRA', label: 'Outra' },
        ],
    },
    q49: {
        field_name: 'sistemaApresentouFuncionamentoContinuoSemFalhas',
        description: (
            <>O sistema apresentou funcionamento contínuo sem falhas?</>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'boolean',
    },
    q50: {
        field_name: 'existemFontesDeSujidade',
        description: (
            <>
                A usina encontra-se livre de possíveis fontes de sujidade
                relevantes ao redor da usina? (plantações, indústrias, estradas
                de chão...)
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q51: {
        field_name: 'diagramaUnifilarVisivelUsina',
        description: <>Existe um diagrama unifilar visível da usina?</>,
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q52: {
        field_name: 'potenciaCaInversorCoincidePotenciaCaProjeto',
        description: (
            <>
                A potência CA do(s) inversor(es) do projeto coincide com a do(s)
                instalado(s)?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q53: {
        field_name: 'potenciaCcModulosCoincidePotenciaCcProjeto',
        description: (
            <>
                A potência CC dos módulos do projeto coincide com a potência CC
                instalada?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q54: {
        field_name: 'tipoInstalacaoCondizComProjeto',
        description: (
            <>
                O tipo de instalação, solo ou cobertura, condiz com o projetado?
            </>
        ),
        allowed: ['TECNICA'],
        type: 'boolean',
    },
    q55: {
        field_name: 'garantiaEquipamentosEntregueProp',
        description: (
            <>
                A garantia, período de cobertura, dos serviços e
                materiais/equipamentos foi apresentada e entregue ao
                proprietário?
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'boolean',
    },
    q56: {
        field_name: 'propSatisfeitoTrabPosVendaEmpresa',
        description: (
            <>
                O proprietário está satisfeito com a execução dos trabalhos e o
                pós-venda da empresa instaladora?
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'boolean',
    },
    q57: {
        field_name: 'producaoCondizenteExpectativa',
        description: (
            <>
                A produção de energia elétrica condiz com a expectativa
                apresentada pela empresa executora do projeto?
            </>
        ),
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'boolean',
    },
    q58: {
        field_name: 'localInstalacaoCondicoesIdeais',
        description: (
            <>
                Na sua percepção, o local de instalação da usina está nas
                condições ideais de geração (orientação dos painéis, inclinação
                dos painéis, ausência de sombreamento e sujidade)? (Escala de
                0=nenhum, 1=Apenas um dos mencionados, 2=Apenas dois dos
                mencionados, 3=Apenas três dos mencionados, 4=Todos mencionados)
            </>
        ),
        allowed: ['TECNICA'],
        type: 'selection',
        options: [
            { value: 'E0', label: '0' },
            { value: 'E1', label: '1' },
            { value: 'E2', label: '2' },
            { value: 'E3', label: '3' },
            { value: 'E5', label: '4' },
        ],
    },
    q59: {
        // novo demaisComentarios
        field_name: 'demaisComentarios',
        description: <>Demais percepções e comentários que achar necessário</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'textarea',
    },
    //Dados complementares da usina
    q60: {
        field_name: 'tipoUsina',
        description: <>Tipo de usina</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'selection',
        options: [
            { value: 'SOLO', label: 'Solo' },
            { value: 'COBERTURA', label: 'Cobertura' },
        ],
    },
    q61: {
        field_name: 'empresaInstaladora',
        description: <>Empresa instaladora</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'textfield',
    },
    q62: {
        field_name: 'dataInicioOperacao',
        description: <>Data de inicio de operação</>,
        allowed: ['PROD', 'EXT', 'TECNICA'],
        type: 'date',
    },
    q63: {
        field_name: 'latitude',
        description: <>Latitude</>,
        allowed: ['EXT', 'TECNICA'],
        type: 'double',
    },
    q64: {
        field_name: 'longitude',
        description: <>Longitude</>,
        allowed: ['EXT', 'TECNICA'],
        type: 'double',
    },
    q65: {
        field_name: 'dataInstalacao',
        description: <>Data de instalação da usina</>,
        allowed: ['EXT', 'TECNICA'],
        type: 'date',
    },
})

export default questions
